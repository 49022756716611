import { createContext } from 'react';

import { useAppConfig } from 'src/hooks/useAppConfig/useAppConfig';
import { SmartHireClient } from 'src/utils/SmartHireClient';

export interface SmartHireClientContextType {
  smartHireClient: SmartHireClient;
}

export const SmartHireClientContext =
  createContext<SmartHireClientContextType | null>(null);

export const SmartHireClientProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { environment } = useAppConfig();
  const smartHireClient = new SmartHireClient(environment);

  return (
    <SmartHireClientContext.Provider
      value={{
        smartHireClient,
      }}
    >
      {children}
    </SmartHireClientContext.Provider>
  );
};
