import {
  Box,
  Column,
  Columns,
  IconChevron,
  Inline,
  Loader,
  MenuItem,
  MenuRenderer,
  Text,
} from 'braid-design-system';
import { useState, useEffect, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { BetaTag } from 'src/components/atoms/BetaTag/BetaTag';
import { UserContext } from 'src/contexts/UserContex/UserContextProvider';
import { useCheckIsLoggedIn } from 'src/hooks/useCheckIsLoggedIn/useCheckIsLoggedIn';
import { useSmartHireClient } from 'src/hooks/useSmartHireClient/useSmartHireClient';
import { topNavLinks } from 'src/routes/routes';

import { Logo } from './components/Logo';

import * as styles from './Nav.css';

export const Nav = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { smartHireClient } = useSmartHireClient();
  const {
    data: user,
    error: userError,
    isFetching: isFetchingUser,
  } = useContext(UserContext);
  const [currentTab, setCurrentTab] = useState('/');
  const isLoggedIn = useCheckIsLoggedIn();

  useEffect(() => {
    setCurrentTab(location.pathname);
  }, [location.pathname]);

  const renderUsername = (open: boolean) => {
    if (userError) {
      return <Text>Failed to load user</Text>;
    }
    if (isFetchingUser) {
      return <Loader size="xsmall" />;
    }
    return (
      <Text>
        {`${user?.name} `}
        <IconChevron direction={open ? 'up' : 'down'} alignY="lowercase" />
      </Text>
    );
  };

  return (
    <Box
      component="nav"
      background={isLoggedIn ? 'brand' : 'surface'}
      role="navigation"
      paddingX="xlarge"
      paddingY={isLoggedIn ? 'none' : 'medium'}
      display="flex"
      alignItems="center"
      className={styles.nav}
    >
      <Columns space="xsmall">
        <Column>
          <Logo color={isLoggedIn ? 'light' : 'dark'} />
        </Column>
        <Column>
          <BetaTag color={isLoggedIn ? 'light' : 'dark'} />
        </Column>
      </Columns>
      {isLoggedIn && (
        <>
          <Box
            component="nav"
            display="flex"
            width="full"
            marginLeft="xlarge"
            alignItems="flexStart"
          >
            {topNavLinks.map((route, index) => {
              const isActive = currentTab.startsWith(route.path);
              return (
                <Box
                  key={index}
                  cursor="pointer"
                  className={[styles.navItem, isActive && styles.navItemActive]}
                  marginX="small"
                  onClick={() => {
                    navigate(route.path);
                  }}
                >
                  <Text>{route.name}</Text>
                </Box>
              );
            })}
          </Box>
          <Box paddingY="large" paddingX="small" className={styles.navButton}>
            <Inline space="none">
              <MenuRenderer
                align="right"
                offsetSpace="small"
                width="medium"
                trigger={(triggerProps, { open }) => (
                  <Box userSelect="none" cursor="pointer" {...triggerProps}>
                    {renderUsername(open)}
                  </Box>
                )}
              >
                <MenuItem onClick={() => navigate('/account')}>
                  Account Details
                </MenuItem>
                <MenuItem
                  onClick={() => smartHireClient.logout()}
                  tone="critical"
                >
                  Sign out
                </MenuItem>
              </MenuRenderer>
            </Inline>
          </Box>
        </>
      )}
    </Box>
  );
};
