import type { NavigateFunction } from 'react-router-dom';

export class NavigationService {
  navigate: NavigateFunction | null;

  constructor() {
    this.navigate = null;
  }

  setNavigator(navigate: NavigateFunction) {
    this.navigate = navigate;
  }

  goToForbidden() {
    if (this.navigate) {
      this.navigate('/forbidden');
    }
  }
}

export const navigationService = new NavigationService();
