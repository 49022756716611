export enum QueryKey {
  COMPANY = 'COMPANY',
  HIRERS = 'HIRERS',
  CURRENT_HIRER = 'CURRENT_HIRER',
  JOBS = 'JOBS',
  JOB = 'JOB',
  MATCH_ID = 'MATCH_ID',
  MATCHES = 'MATCHES',
  CANDIDATE_ID = 'CANDIDATE_ID',
  NOTES = 'NOTES',
  STATUS = 'STATUS',
}
